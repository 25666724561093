import React from "react"
import RacepageFS from "../components/racepageFS"

const Race2021 = () => {
  const startlist = {
    info: {
      providedBy: "FirstCycling.com",
      race: "E3 Saxo Bank Classic",
      edition: 2021,
      what: "Startlist",
      updated: "2021-03-25 16:17:16",
      linkRiders: "https://firstcycling.com/rider.php?r={id}",
      linkTeams: "https://firstcycling.com/team.php?l={teamId}",
    },
    teams: {
      21797: {
        teamId: 21797,
        teamUciCode: "UXT",
        teamName: "Uno-X Pro Cycling Team",
        teamNationCode: "NOR",
        teamNationName: "Norway",
      },
      21798: {
        teamId: 21798,
        teamUciCode: "ACT",
        teamName: "AG2R Citro\u00ebn Team",
        teamNationCode: "FRA",
        teamNationName: "France",
      },
      21799: {
        teamId: 21799,
        teamUciCode: "AST",
        teamName: "Astana-Premier Tech",
        teamNationCode: "KAZ",
        teamNationName: "Kazakhstan",
      },
      21800: {
        teamId: 21800,
        teamUciCode: "TBV",
        teamName: "Bahrain Victorious",
        teamNationCode: "BHR",
        teamNationName: "Bahrain",
      },
      21805: {
        teamId: 21805,
        teamUciCode: "BOH",
        teamName: "BORA-hansgrohe",
        teamNationCode: "GER",
        teamNationName: "Germany",
      },
      21807: {
        teamId: 21807,
        teamUciCode: "COF",
        teamName: "Cofidis",
        teamNationCode: "FRA",
        teamNationName: "France",
      },
      21808: {
        teamId: 21808,
        teamUciCode: "DQT",
        teamName: "Deceuninck-Quick Step",
        teamNationCode: "BEL",
        teamNationName: "Belgium",
      },
      21811: {
        teamId: 21811,
        teamUciCode: "EFN",
        teamName: "EF Education-NIPPO",
        teamNationCode: "USA",
        teamNationName: "USA",
      },
      21813: {
        teamId: 21813,
        teamUciCode: "GFC",
        teamName: "Groupama-FDJ",
        teamNationCode: "FRA",
        teamNationName: "France",
      },
      21817: {
        teamId: 21817,
        teamUciCode: "ISN",
        teamName: "Israel Start-Up Nation",
        teamNationCode: "ISR",
        teamNationName: "Israel",
      },
      21819: {
        teamId: 21819,
        teamUciCode: "LTS",
        teamName: "Lotto Soudal",
        teamNationCode: "BEL",
        teamNationName: "Belgium",
      },
      21821: {
        teamId: 21821,
        teamUciCode: "BEX",
        teamName: "Team BikeExchange",
        teamNationCode: "AUS",
        teamNationName: "Australia",
      },
      21822: {
        teamId: 21822,
        teamUciCode: "MOV",
        teamName: "Movistar Team",
        teamNationCode: "ESP",
        teamNationName: "Spain",
      },
      21824: {
        teamId: 21824,
        teamUciCode: "TQA",
        teamName: "Team Qhubeka ASSOS",
        teamNationCode: "RSA",
        teamNationName: "South Africa",
      },
      21826: {
        teamId: 21826,
        teamUciCode: "IGD",
        teamName: "INEOS Grenadiers",
        teamNationCode: "GBR",
        teamNationName: "Great Britain",
      },
      21829: {
        teamId: 21829,
        teamUciCode: "TJV",
        teamName: "Jumbo-Visma",
        teamNationCode: "NED",
        teamNationName: "Netherlands",
      },
      21832: {
        teamId: 21832,
        teamUciCode: "DSM",
        teamName: "Team DSM",
        teamNationCode: "GER",
        teamNationName: "Germany",
      },
      21836: {
        teamId: 21836,
        teamUciCode: "TFS",
        teamName: "Trek-Segafredo",
        teamNationCode: "USA",
        teamNationName: "USA",
      },
      21838: {
        teamId: 21838,
        teamUciCode: "UAD",
        teamName: "UAE Team Emirates",
        teamNationCode: "UAE",
        teamNationName: "United Arab Emirates",
      },
      21844: {
        teamId: 21844,
        teamUciCode: "AFC",
        teamName: "Alpecin-Fenix",
        teamNationCode: "BEL",
        teamNationName: "Belgium",
      },
      21852: {
        teamId: 21852,
        teamUciCode: "BWB",
        teamName: "Bingoal-WB",
        teamNationCode: "BEL",
        teamNationName: "Belgium",
      },
      21855: {
        teamId: 21855,
        teamUciCode: "IWG",
        teamName: "Intermarch\u00e9-Wanty-Gobert Mat\u00e9riaux",
        teamNationCode: "BEL",
        teamNationName: "Belgium",
      },
      21863: {
        teamId: 21863,
        teamUciCode: "SVB",
        teamName: "Sport Vlaanderen-Baloise",
        teamNationCode: "BEL",
        teamNationName: "Belgium",
      },
      21867: {
        teamId: 21867,
        teamUciCode: "TDE",
        teamName: "Total Direct Energie",
        teamNationCode: "FRA",
        teamNationName: "France",
      },
      21869: {
        teamId: 21869,
        teamUciCode: "THR",
        teamName: "Vini Zab\u00fa",
        teamNationCode: "ITA",
        teamNationName: "Italy",
      },
    },
    riders: {
      1: {
        id: 4669,
        startno: 1,
        firstName: "Zdenek",
        lastName: "Stybar",
        nationCode: "CZE",
        nationName: "Czech Republic",
        birthDate: "1985-12-11",
        teamId: 21808,
        teamName: "Deceuninck-Quick Step",
        stillInTheRace: "Y",
      },
      2: {
        id: 10971,
        startno: 2,
        firstName: "Yves",
        lastName: "Lampaert",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1991-04-10",
        teamId: 21808,
        teamName: "Deceuninck-Quick Step",
        stillInTheRace: "Y",
      },
      3: {
        id: 1970,
        startno: 3,
        firstName: "Tim",
        lastName: "Declercq",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1989-03-21",
        teamId: 21808,
        teamName: "Deceuninck-Quick Step",
        stillInTheRace: "Y",
      },
      4: {
        id: 26504,
        startno: 4,
        firstName: "Davide",
        lastName: "Ballerini",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1994-09-21",
        teamId: 21808,
        teamName: "Deceuninck-Quick Step",
        stillInTheRace: "Y",
      },
      5: {
        id: 12481,
        startno: 5,
        firstName: "Florian",
        lastName: "S\u00e9n\u00e9chal",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "1993-07-10",
        teamId: 21808,
        teamName: "Deceuninck-Quick Step",
        stillInTheRace: "Y",
      },
      6: {
        id: 8076,
        startno: 6,
        firstName: "Bert",
        lastName: "Van Lerberghe",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1992-09-29",
        teamId: 21808,
        teamName: "Deceuninck-Quick Step",
        stillInTheRace: "Y",
      },
      7: {
        id: 27121,
        startno: 7,
        firstName: "Kasper",
        lastName: "Asgreen",
        nationCode: "DEN",
        nationName: "Denmark",
        birthDate: "1995-02-08",
        teamId: 21808,
        teamName: "Deceuninck-Quick Step",
        stillInTheRace: "Y",
      },
      11: {
        id: 174,
        startno: 11,
        firstName: "Philippe",
        lastName: "Gilbert",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1982-07-05",
        teamId: 21819,
        teamName: "Lotto Soudal",
        stillInTheRace: "Y",
      },
      12: {
        id: 1013,
        startno: 12,
        firstName: "John",
        lastName: "Degenkolb",
        nationCode: "GER",
        nationName: "Germany",
        birthDate: "1989-01-07",
        teamId: 21819,
        teamName: "Lotto Soudal",
        stillInTheRace: "Y",
      },
      13: {
        id: 16144,
        startno: 13,
        firstName: "Frederik",
        lastName: "Frison",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1992-07-28",
        teamId: 21819,
        teamName: "Lotto Soudal",
        stillInTheRace: "Y",
      },
      14: {
        id: 9042,
        startno: 14,
        firstName: "Jasper",
        lastName: "De Buyst",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1993-11-24",
        teamId: 21819,
        teamName: "Lotto Soudal",
        stillInTheRace: "Y",
      },
      15: {
        id: 69912,
        startno: 15,
        firstName: "Florian",
        lastName: "Vermeersch",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1999-03-12",
        teamId: 21819,
        teamName: "Lotto Soudal",
        stillInTheRace: "Y",
      },
      16: {
        id: 46003,
        startno: 16,
        firstName: "Stefano",
        lastName: "Oldani",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1998-01-10",
        teamId: 21819,
        teamName: "Lotto Soudal",
        stillInTheRace: "Y",
      },
      17: {
        id: 53489,
        startno: 17,
        firstName: "Brent",
        lastName: "Van Moer",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1998-01-12",
        teamId: 21819,
        teamName: "Lotto Soudal",
        stillInTheRace: "Y",
      },
      21: {
        id: 16581,
        startno: 21,
        firstName: "Aim\u00e9",
        lastName: "De Gendt",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1994-06-17",
        teamId: 21855,
        teamName: "Intermarch\u00e9-Wanty-Gobert Mat\u00e9riaux",
        stillInTheRace: "Y",
      },
      22: {
        id: 10948,
        startno: 22,
        firstName: "Ludwig",
        lastName: "De Winter",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1992-12-31",
        teamId: 21855,
        teamName: "Intermarch\u00e9-Wanty-Gobert Mat\u00e9riaux",
        stillInTheRace: "Y",
      },
      23: {
        id: 3187,
        startno: 23,
        firstName: "Boy",
        lastName: "van Poppel",
        nationCode: "NED",
        nationName: "Netherlands",
        birthDate: "1988-01-18",
        teamId: 21855,
        teamName: "Intermarch\u00e9-Wanty-Gobert Mat\u00e9riaux",
        stillInTheRace: "Y",
      },
      24: {
        id: 10802,
        startno: 24,
        firstName: "Taco",
        lastName: "van der Hoorn",
        nationCode: "NED",
        nationName: "Netherlands",
        birthDate: "1993-12-04",
        teamId: 21855,
        teamName: "Intermarch\u00e9-Wanty-Gobert Mat\u00e9riaux",
        stillInTheRace: "Y",
      },
      25: {
        id: 9349,
        startno: 25,
        firstName: "Jonas",
        lastName: "Koch",
        nationCode: "GER",
        nationName: "Germany",
        birthDate: "1993-06-25",
        teamId: 21855,
        teamName: "Intermarch\u00e9-Wanty-Gobert Mat\u00e9riaux",
        stillInTheRace: "Y",
      },
      26: {
        id: 3147,
        startno: 26,
        firstName: "Andrea",
        lastName: "Pasqualon",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1988-01-02",
        teamId: 21855,
        teamName: "Intermarch\u00e9-Wanty-Gobert Mat\u00e9riaux",
        stillInTheRace: "Y",
      },
      27: {
        id: 9073,
        startno: 27,
        firstName: "Loic",
        lastName: "Vliegen",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1993-12-20",
        teamId: 21855,
        teamName: "Intermarch\u00e9-Wanty-Gobert Mat\u00e9riaux",
        stillInTheRace: "Y",
      },
      31: {
        id: 150,
        startno: 31,
        firstName: "Greg",
        lastName: "Van Avermaet",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1985-05-17",
        teamId: 21798,
        teamName: "AG2R Citro\u00ebn Team",
        stillInTheRace: "Y",
      },
      32: {
        id: 22682,
        startno: 32,
        firstName: "Oliver",
        lastName: "Naesen",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1990-09-16",
        teamId: 21798,
        teamName: "AG2R Citro\u00ebn Team",
        stillInTheRace: "Y",
      },
      33: {
        id: 37427,
        startno: 33,
        firstName: "Stan",
        lastName: "Dewulf",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1997-12-20",
        teamId: 21798,
        teamName: "AG2R Citro\u00ebn Team",
        stillInTheRace: "Y",
      },
      34: {
        id: 327,
        startno: 34,
        firstName: "Michael",
        lastName: "Sch\u00e4r",
        nationCode: "SUI",
        nationName: "Switzerland",
        birthDate: "1986-09-29",
        teamId: 21798,
        teamName: "AG2R Citro\u00ebn Team",
        stillInTheRace: "Y",
      },
      35: {
        id: 27304,
        startno: 35,
        firstName: "Damien",
        lastName: "Touz\u00e9",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "1996-07-07",
        teamId: 21798,
        teamName: "AG2R Citro\u00ebn Team",
        stillInTheRace: "Y",
      },
      36: {
        id: 14742,
        startno: 36,
        firstName: "Julien",
        lastName: "Duval",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "1990-05-27",
        teamId: 21798,
        teamName: "AG2R Citro\u00ebn Team",
        stillInTheRace: "Y",
      },
      37: {
        id: 7580,
        startno: 37,
        firstName: "Gijs",
        lastName: "Van Hoecke",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1991-11-12",
        teamId: 21798,
        teamName: "AG2R Citro\u00ebn Team",
        stillInTheRace: "Y",
      },
      41: {
        id: 18258,
        startno: 41,
        firstName: "Wout",
        lastName: "Van Aert",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1994-09-15",
        teamId: 21829,
        teamName: "Jumbo-Visma",
        stillInTheRace: "Y",
      },
      42: {
        id: 45433,
        startno: 42,
        firstName: "David",
        lastName: "Dekker",
        nationCode: "NED",
        nationName: "Netherlands",
        birthDate: "1998-02-02",
        teamId: 21829,
        teamName: "Jumbo-Visma",
        stillInTheRace: "Y",
      },
      43: {
        id: 37308,
        startno: 43,
        firstName: "Pascal",
        lastName: "Eenkhoorn",
        nationCode: "NED",
        nationName: "Netherlands",
        birthDate: "1997-02-08",
        teamId: 21829,
        teamName: "Jumbo-Visma",
        stillInTheRace: "Y",
      },
      44: {
        id: 22133,
        startno: 44,
        firstName: "Timo",
        lastName: "Roosen",
        nationCode: "NED",
        nationName: "Netherlands",
        birthDate: "1993-01-11",
        teamId: 21829,
        teamName: "Jumbo-Visma",
        stillInTheRace: "Y",
      },
      45: {
        id: 27097,
        startno: 45,
        firstName: "Edoardo",
        lastName: "Affini",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1996-06-24",
        teamId: 21829,
        teamName: "Jumbo-Visma",
        stillInTheRace: "Y",
      },
      46: {
        id: 16583,
        startno: 46,
        firstName: "Nathan",
        lastName: "Van Hooydonck",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1995-10-12",
        teamId: 21829,
        teamName: "Jumbo-Visma",
        stillInTheRace: "Y",
      },
      47: {
        id: 367,
        startno: 47,
        firstName: "Maarten",
        lastName: "Wynants",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1982-05-13",
        teamId: 21829,
        teamName: "Jumbo-Visma",
        stillInTheRace: "Y",
      },
      51: {
        id: 777,
        startno: 51,
        firstName: "Daniel",
        lastName: "Oss",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1987-01-13",
        teamId: 21805,
        teamName: "BORA-hansgrohe",
        stillInTheRace: "Y",
      },
      52: {
        id: 92,
        startno: 52,
        firstName: "Marcus",
        lastName: "Burghardt",
        nationCode: "GER",
        nationName: "Germany",
        birthDate: "1983-06-30",
        teamId: 21805,
        teamName: "BORA-hansgrohe",
        stillInTheRace: "Y",
      },
      53: {
        id: 37439,
        startno: 53,
        firstName: "Patrick",
        lastName: "Gamper",
        nationCode: "AUT",
        nationName: "Austria",
        birthDate: "1997-02-18",
        teamId: 21805,
        teamName: "BORA-hansgrohe",
        stillInTheRace: "Y",
      },
      54: {
        id: 18260,
        startno: 54,
        firstName: "Nils",
        lastName: "Politt",
        nationCode: "GER",
        nationName: "Germany",
        birthDate: "1994-03-06",
        teamId: 21805,
        teamName: "BORA-hansgrohe",
        stillInTheRace: "Y",
      },
      55: {
        id: 45352,
        startno: 55,
        firstName: "Matthew",
        lastName: "Walls",
        nationCode: "GBR",
        nationName: "Great Britain",
        birthDate: "1998-04-20",
        teamId: 21805,
        teamName: "BORA-hansgrohe",
        stillInTheRace: "Y",
      },
      56: {
        id: 2172,
        startno: 56,
        firstName: "Juraj",
        lastName: "Sagan",
        nationCode: "SVK",
        nationName: "Slovakia",
        birthDate: "1988-12-23",
        teamId: 21805,
        teamName: "BORA-hansgrohe",
        stillInTheRace: "Y",
      },
      57: {
        id: 361,
        startno: 57,
        firstName: "Maciej",
        lastName: "Bodnar",
        nationCode: "POL",
        nationName: "Poland",
        birthDate: "1985-03-07",
        teamId: 21805,
        teamName: "BORA-hansgrohe",
        stillInTheRace: "Y",
      },
      61: {
        id: 1581,
        startno: 61,
        firstName: "Sep",
        lastName: "Vanmarcke",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1988-07-28",
        teamId: 21817,
        teamName: "Israel Start-Up Nation",
        stillInTheRace: "Y",
      },
      62: {
        id: 377,
        startno: 62,
        firstName: "Andr\u00e9",
        lastName: "Greipel",
        nationCode: "GER",
        nationName: "Germany",
        birthDate: "1982-07-16",
        teamId: 21817,
        teamName: "Israel Start-Up Nation",
        stillInTheRace: "Y",
      },
      63: {
        id: 70759,
        startno: 63,
        firstName: "Alexis",
        lastName: "Renard",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "1999-06-01",
        teamId: 21817,
        teamName: "Israel Start-Up Nation",
        stillInTheRace: "Y",
      },
      64: {
        id: 16615,
        startno: 64,
        firstName: "Mads",
        lastName: "W\u00fcrtz Schmidt",
        nationCode: "DEN",
        nationName: "Denmark",
        birthDate: "1994-03-31",
        teamId: 21817,
        teamName: "Israel Start-Up Nation",
        stillInTheRace: "Y",
      },
      65: {
        id: 7731,
        startno: 65,
        firstName: "Rick",
        lastName: "Zabel",
        nationCode: "GER",
        nationName: "Germany",
        birthDate: "1993-12-07",
        teamId: 21817,
        teamName: "Israel Start-Up Nation",
        stillInTheRace: "Y",
      },
      66: {
        id: 37384,
        startno: 66,
        firstName: "Norman",
        lastName: "Vahtra",
        nationCode: "EST",
        nationName: "Estonia",
        birthDate: "1996-11-23",
        teamId: 21817,
        teamName: "Israel Start-Up Nation",
        stillInTheRace: "Y",
      },
      67: {
        id: 16787,
        startno: 67,
        firstName: "Jenthe",
        lastName: "Biermans",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1995-10-30",
        teamId: 21817,
        teamName: "Israel Start-Up Nation",
        stillInTheRace: "Y",
      },
      71: {
        id: 16786,
        startno: 71,
        firstName: "Tiesj",
        lastName: "Benoot",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1994-03-11",
        teamId: 21832,
        teamName: "Team DSM",
        stillInTheRace: "Y",
      },
      72: {
        id: 16817,
        startno: 72,
        firstName: "Nico",
        lastName: "Denz",
        nationCode: "GER",
        nationName: "Germany",
        birthDate: "1994-02-15",
        teamId: 21832,
        teamName: "Team DSM",
        stillInTheRace: "Y",
      },
      73: {
        id: 16610,
        startno: 73,
        firstName: "S\u00f8ren Kragh",
        lastName: "Andersen",
        nationCode: "DEN",
        nationName: "Denmark",
        birthDate: "1994-08-10",
        teamId: 21832,
        teamName: "Team DSM",
        stillInTheRace: "Y",
      },
      74: {
        id: 38146,
        startno: 74,
        firstName: "Joris",
        lastName: "Nieuwenhuis",
        nationCode: "NED",
        nationName: "Netherlands",
        birthDate: "1996-02-11",
        teamId: 21832,
        teamName: "Team DSM",
        stillInTheRace: "Y",
      },
      75: {
        id: 39096,
        startno: 75,
        firstName: "Martin",
        lastName: "Salmon",
        nationCode: "GER",
        nationName: "Germany",
        birthDate: "1997-10-29",
        teamId: 21832,
        teamName: "Team DSM",
        stillInTheRace: "Y",
      },
      76: {
        id: 45368,
        startno: 76,
        firstName: "Nils",
        lastName: "Eekhoff",
        nationCode: "NED",
        nationName: "Netherlands",
        birthDate: "1998-01-23",
        teamId: 21832,
        teamName: "Team DSM",
        stillInTheRace: "Y",
      },
      77: {
        id: 6414,
        startno: 77,
        firstName: "Jasha",
        lastName: "S\u00fctterlin",
        nationCode: "GER",
        nationName: "Germany",
        birthDate: "1992-11-04",
        teamId: 21832,
        teamName: "Team DSM",
        stillInTheRace: "Y",
      },
      81: {
        id: 5059,
        startno: 81,
        firstName: "Jasper",
        lastName: "Stuyven",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1992-04-17",
        teamId: 21836,
        teamName: "Trek-Segafredo",
        stillInTheRace: "Y",
      },
      82: {
        id: 8813,
        startno: 82,
        firstName: "Emils",
        lastName: "Liepins",
        nationCode: "LAT",
        nationName: "Latvia",
        birthDate: "1992-10-29",
        teamId: 21836,
        teamName: "Trek-Segafredo",
        stillInTheRace: "Y",
      },
      83: {
        id: 16793,
        startno: 83,
        firstName: "Mads",
        lastName: "Pedersen",
        nationCode: "DEN",
        nationName: "Denmark",
        birthDate: "1995-12-18",
        teamId: 21836,
        teamName: "Trek-Segafredo",
        stillInTheRace: "Y",
      },
      84: {
        id: 191,
        startno: 84,
        firstName: "Koen",
        lastName: "De Kort",
        nationCode: "NED",
        nationName: "Netherlands",
        birthDate: "1982-09-08",
        teamId: 21836,
        teamName: "Trek-Segafredo",
        stillInTheRace: "Y",
      },
      85: {
        id: 95151,
        startno: 85,
        firstName: "Quinn",
        lastName: "Simmons",
        nationCode: "USA",
        nationName: "USA",
        birthDate: "2001-05-08",
        teamId: 21836,
        teamName: "Trek-Segafredo",
        stillInTheRace: "Y",
      },
      86: {
        id: 8740,
        startno: 86,
        firstName: "Alex",
        lastName: "Kirsch",
        nationCode: "LUX",
        nationName: "Luxembourg",
        birthDate: "1992-06-12",
        teamId: 21836,
        teamName: "Trek-Segafredo",
        stillInTheRace: "Y",
      },
      87: {
        id: 10382,
        startno: 87,
        firstName: "Edward",
        lastName: "Theuns",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1991-04-30",
        teamId: 21836,
        teamName: "Trek-Segafredo",
        stillInTheRace: "Y",
      },
      91: {
        id: 65025,
        startno: 91,
        firstName: "Tom",
        lastName: "Pidcock",
        nationCode: "GBR",
        nationName: "Great Britain",
        birthDate: "1999-07-30",
        teamId: 21826,
        teamName: "INEOS Grenadiers",
        stillInTheRace: "Y",
      },
      92: {
        id: 15686,
        startno: 92,
        firstName: "Owain",
        lastName: "Doull",
        nationCode: "GBR",
        nationName: "Great Britain",
        birthDate: "1993-02-05",
        teamId: 21826,
        teamName: "INEOS Grenadiers",
        stillInTheRace: "Y",
      },
      93: {
        id: 926,
        startno: 93,
        firstName: "Michal",
        lastName: "Golas",
        nationCode: "POL",
        nationName: "Poland",
        birthDate: "1984-04-29",
        teamId: 21826,
        teamName: "INEOS Grenadiers",
        stillInTheRace: "Y",
      },
      94: {
        id: 41249,
        startno: 94,
        firstName: "Jhonnatan",
        lastName: "Narvaez",
        nationCode: "ECU",
        nationName: "Ecuador",
        birthDate: "1997-03-04",
        teamId: 21826,
        teamName: "INEOS Grenadiers",
        stillInTheRace: "Y",
      },
      95: {
        id: 20581,
        startno: 95,
        firstName: "Leonardo",
        lastName: "Basso",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1993-12-25",
        teamId: 21826,
        teamName: "INEOS Grenadiers",
        stillInTheRace: "Y",
      },
      96: {
        id: 7724,
        startno: 96,
        firstName: "Dylan",
        lastName: "Van Baarle",
        nationCode: "NED",
        nationName: "Netherlands",
        birthDate: "1992-05-21",
        teamId: 21826,
        teamName: "INEOS Grenadiers",
        stillInTheRace: "Y",
      },
      97: {
        id: 1867,
        startno: 97,
        firstName: "Cameron",
        lastName: "Wurf",
        nationCode: "AUS",
        nationName: "Australia",
        birthDate: "1983-08-03",
        teamId: 21826,
        teamName: "INEOS Grenadiers",
        stillInTheRace: "Y",
      },
      101: {
        id: 19274,
        startno: 101,
        firstName: "Victor",
        lastName: "Campenaerts",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1991-10-28",
        teamId: 21824,
        teamName: "Team Qhubeka ASSOS",
        stillInTheRace: "Y",
      },
      102: {
        id: 568,
        startno: 102,
        firstName: "Simon",
        lastName: "Clarke",
        nationCode: "AUS",
        nationName: "Australia",
        birthDate: "1986-07-18",
        teamId: 21824,
        teamName: "Team Qhubeka ASSOS",
        stillInTheRace: "Y",
      },
      103: {
        id: 2146,
        startno: 103,
        firstName: "Dimitri",
        lastName: "Claeys",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1987-06-18",
        teamId: 21824,
        teamName: "Team Qhubeka ASSOS",
        stillInTheRace: "Y",
      },
      104: {
        id: 6100,
        startno: 104,
        firstName: "Lasse",
        lastName: "Norman Hansen",
        nationCode: "DEN",
        nationName: "Denmark",
        birthDate: "1992-02-11",
        teamId: 21824,
        teamName: "Team Qhubeka ASSOS",
        stillInTheRace: "Y",
      },
      105: {
        id: 4001,
        startno: 105,
        firstName: "Bert-Jan",
        lastName: "Lindeman",
        nationCode: "NED",
        nationName: "Netherlands",
        birthDate: "1989-06-16",
        teamId: 21824,
        teamName: "Team Qhubeka ASSOS",
        stillInTheRace: "Y",
      },
      106: {
        id: 16791,
        startno: 106,
        firstName: "Emil",
        lastName: "Vinjebo",
        nationCode: "DEN",
        nationName: "Denmark",
        birthDate: "1994-03-24",
        teamId: 21824,
        teamName: "Team Qhubeka ASSOS",
        stillInTheRace: "Y",
      },
      107: {
        id: 9894,
        startno: 107,
        firstName: "Michael",
        lastName: "Gogl",
        nationCode: "AUT",
        nationName: "Austria",
        birthDate: "1993-11-04",
        teamId: 21824,
        teamName: "Team Qhubeka ASSOS",
        stillInTheRace: "Y",
      },
      111: {
        id: 2338,
        startno: 111,
        firstName: "Sonny",
        lastName: "Colbrelli",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1990-05-17",
        teamId: 21800,
        teamName: "Bahrain Victorious",
        stillInTheRace: "Y",
      },
      112: {
        id: 9014,
        startno: 112,
        firstName: "Chun Kai",
        lastName: "Feng",
        nationCode: "TPE",
        nationName: "Taiwan",
        birthDate: "1988-11-02",
        teamId: 21800,
        teamName: "Bahrain Victorious",
        stillInTheRace: "Y",
      },
      113: {
        id: 6246,
        startno: 113,
        firstName: "Marco",
        lastName: "Haller",
        nationCode: "AUT",
        nationName: "Austria",
        birthDate: "1991-01-04",
        teamId: 21800,
        teamName: "Bahrain Victorious",
        stillInTheRace: "Y",
      },
      114: {
        id: 80489,
        startno: 114,
        firstName: "Jonathan",
        lastName: "Milan",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "2000-10-01",
        teamId: 21800,
        teamName: "Bahrain Victorious",
        stillInTheRace: "Y",
      },
      115: {
        id: 376,
        startno: 115,
        firstName: "Marcel",
        lastName: "Sieberg",
        nationCode: "GER",
        nationName: "Germany",
        birthDate: "1982-04-30",
        teamId: 21800,
        teamName: "Bahrain Victorious",
        stillInTheRace: "Y",
      },
      116: {
        id: 120,
        startno: 116,
        firstName: "Heinrich",
        lastName: "Haussler",
        nationCode: "AUS",
        nationName: "Australia",
        birthDate: "1984-02-25",
        teamId: 21800,
        teamName: "Bahrain Victorious",
        stillInTheRace: "Y",
      },
      117: {
        id: 8068,
        startno: 117,
        firstName: "Dylan",
        lastName: "Teuns",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1992-03-01",
        teamId: 21800,
        teamName: "Bahrain Victorious",
        stillInTheRace: "Y",
      },
      121: {
        id: 23372,
        startno: 121,
        firstName: "Artyom",
        lastName: "Zakharov",
        nationCode: "KAZ",
        nationName: "Kazakhstan",
        birthDate: "1991-10-27",
        teamId: 21799,
        teamName: "Astana-Premier Tech",
        stillInTheRace: "Y",
      },
      122: {
        id: 75603,
        startno: 122,
        firstName: "Yevgeniy",
        lastName: "Fedorov",
        nationCode: "KAZ",
        nationName: "Kazakhstan",
        birthDate: "2000-02-16",
        teamId: 21799,
        teamName: "Astana-Premier Tech",
        stillInTheRace: "Y",
      },
      123: {
        id: 27257,
        startno: 123,
        firstName: "Yevgeniy",
        lastName: "Gidich",
        nationCode: "KAZ",
        nationName: "Kazakhstan",
        birthDate: "1996-05-19",
        teamId: 21799,
        teamName: "Astana-Premier Tech",
        stillInTheRace: "Y",
      },
      124: {
        id: 5836,
        startno: 124,
        firstName: "Dmitriy",
        lastName: "Gruzdev",
        nationCode: "KAZ",
        nationName: "Kazakhstan",
        birthDate: "1986-03-13",
        teamId: 21799,
        teamName: "Astana-Premier Tech",
        stillInTheRace: "Y",
      },
      125: {
        id: 3199,
        startno: 125,
        firstName: "Hugo",
        lastName: "Houle",
        nationCode: "CAN",
        nationName: "Canada",
        birthDate: "1990-09-27",
        teamId: 21799,
        teamName: "Astana-Premier Tech",
        stillInTheRace: "Y",
      },
      126: {
        id: 14717,
        startno: 126,
        firstName: "Davide",
        lastName: "Martinelli",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1993-05-31",
        teamId: 21799,
        teamName: "Astana-Premier Tech",
        stillInTheRace: "Y",
      },
      127: {
        id: 16594,
        startno: 127,
        firstName: "Ben",
        lastName: "Perry",
        nationCode: "CAN",
        nationName: "Canada",
        birthDate: "1994-03-07",
        teamId: 21799,
        teamName: "Astana-Premier Tech",
        stillInTheRace: "Y",
      },
      131: {
        id: 1276,
        startno: 131,
        firstName: "Jelle",
        lastName: "Wallays",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1989-05-11",
        teamId: 21807,
        teamName: "Cofidis",
        stillInTheRace: "Y",
      },
      132: {
        id: 16690,
        startno: 132,
        firstName: "Tom",
        lastName: "Bohli",
        nationCode: "SUI",
        nationName: "Switzerland",
        birthDate: "1994-01-17",
        teamId: 21807,
        teamName: "Cofidis",
        stillInTheRace: "Y",
      },
      133: {
        id: 20454,
        startno: 133,
        firstName: "Simone",
        lastName: "Consonni",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1994-09-12",
        teamId: 21807,
        teamName: "Cofidis",
        stillInTheRace: "Y",
      },
      134: {
        id: 1982,
        startno: 134,
        firstName: "Jempy",
        lastName: "Drucker",
        nationCode: "LUX",
        nationName: "Luxembourg",
        birthDate: "1986-09-03",
        teamId: 21807,
        teamName: "Cofidis",
        stillInTheRace: "Y",
      },
      135: {
        id: 37294,
        startno: 135,
        firstName: "Szymon",
        lastName: "Sajnok",
        nationCode: "POL",
        nationName: "Poland",
        birthDate: "1997-08-24",
        teamId: 21807,
        teamName: "Cofidis",
        stillInTheRace: "Y",
      },
      136: {
        id: 37286,
        startno: 136,
        firstName: "Andr\u00e9",
        lastName: "Carvalho",
        nationCode: "POR",
        nationName: "Portugal",
        birthDate: "1997-10-31",
        teamId: 21807,
        teamName: "Cofidis",
        stillInTheRace: "Y",
      },
      137: {
        id: 27331,
        startno: 137,
        firstName: "Piet",
        lastName: "Allegaert",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1995-01-20",
        teamId: 21807,
        teamName: "Cofidis",
        stillInTheRace: "Y",
      },
      141: {
        id: 20584,
        startno: 141,
        firstName: "Alberto",
        lastName: "Bettiol",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1993-10-29",
        teamId: 21811,
        teamName: "EF Education-NIPPO",
        stillInTheRace: "Y",
      },
      142: {
        id: 920,
        startno: 142,
        firstName: "Jens",
        lastName: "Keukeleire",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1988-11-23",
        teamId: 21811,
        teamName: "EF Education-NIPPO",
        stillInTheRace: "Y",
      },
      143: {
        id: 46157,
        startno: 143,
        firstName: "Stefan",
        lastName: "Bissegger",
        nationCode: "SUI",
        nationName: "Switzerland",
        birthDate: "1998-09-13",
        teamId: 21811,
        teamName: "EF Education-NIPPO",
        stillInTheRace: "Y",
      },
      144: {
        id: 190,
        startno: 144,
        firstName: "Fumiyuki",
        lastName: "Beppu",
        nationCode: "JPN",
        nationName: "Japan",
        birthDate: "1983-04-10",
        teamId: 21811,
        teamName: "EF Education-NIPPO",
        stillInTheRace: "Y",
      },
      145: {
        id: 312,
        startno: 145,
        firstName: "Sebastian",
        lastName: "Langeveld",
        nationCode: "NED",
        nationName: "Netherlands",
        birthDate: "1985-01-17",
        teamId: 21811,
        teamName: "EF Education-NIPPO",
        stillInTheRace: "Y",
      },
      146: {
        id: 38164,
        startno: 146,
        firstName: "Julius",
        lastName: "Van Den Berg",
        nationCode: "NED",
        nationName: "Netherlands",
        birthDate: "1996-10-23",
        teamId: 21811,
        teamName: "EF Education-NIPPO",
        stillInTheRace: "Y",
      },
      147: {
        id: 46161,
        startno: 147,
        firstName: "Jonas",
        lastName: "Rutsch",
        nationCode: "GER",
        nationName: "Germany",
        birthDate: "1998-01-24",
        teamId: 21811,
        teamName: "EF Education-NIPPO",
        stillInTheRace: "Y",
      },
      151: {
        id: 45620,
        startno: 151,
        firstName: "Alexys",
        lastName: "Brunel",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "1998-10-10",
        teamId: 21813,
        teamName: "Groupama-FDJ",
        stillInTheRace: "Y",
      },
      152: {
        id: 37325,
        startno: 152,
        firstName: "Kevin",
        lastName: "Geniets",
        nationCode: "LUX",
        nationName: "Luxembourg",
        birthDate: "1997-01-09",
        teamId: 21813,
        teamName: "Groupama-FDJ",
        stillInTheRace: "Y",
      },
      153: {
        id: 16724,
        startno: 153,
        firstName: "Stefan",
        lastName: "K\u00fcng",
        nationCode: "SUI",
        nationName: "Switzerland",
        birthDate: "1993-11-16",
        teamId: 21813,
        teamName: "Groupama-FDJ",
        stillInTheRace: "Y",
      },
      154: {
        id: 10871,
        startno: 154,
        firstName: "Olivier",
        lastName: "Le Gac",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "1993-08-27",
        teamId: 21813,
        teamName: "Groupama-FDJ",
        stillInTheRace: "Y",
      },
      155: {
        id: 12493,
        startno: 155,
        firstName: "Fabian",
        lastName: "Lienhard",
        nationCode: "SUI",
        nationName: "Switzerland",
        birthDate: "1993-09-03",
        teamId: 21813,
        teamName: "Groupama-FDJ",
        stillInTheRace: "Y",
      },
      156: {
        id: 605,
        startno: 156,
        firstName: "Jacopo",
        lastName: "Guarnieri",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1987-08-14",
        teamId: 21813,
        teamName: "Groupama-FDJ",
        stillInTheRace: "Y",
      },
      157: {
        id: 65036,
        startno: 157,
        firstName: "Jake",
        lastName: "Stewart",
        nationCode: "GBR",
        nationName: "Great Britain",
        birthDate: "1999-10-02",
        teamId: 21813,
        teamName: "Groupama-FDJ",
        stillInTheRace: "Y",
      },
      161: {
        id: 37373,
        startno: 161,
        firstName: "Gabriel",
        lastName: "Cullaigh",
        nationCode: "GBR",
        nationName: "Great Britain",
        birthDate: "1996-04-08",
        teamId: 21822,
        teamName: "Movistar Team",
        stillInTheRace: "Y",
      },
      162: {
        id: 333,
        startno: 162,
        firstName: "Imanol",
        lastName: "Erviti",
        nationCode: "ESP",
        nationName: "Spain",
        birthDate: "1983-11-15",
        teamId: 21822,
        teamName: "Movistar Team",
        stillInTheRace: "Y",
      },
      163: {
        id: 11828,
        startno: 163,
        firstName: "Ivan",
        lastName: "Garcia",
        nationCode: "ESP",
        nationName: "Spain",
        birthDate: "1995-11-20",
        teamId: 21822,
        teamName: "Movistar Team",
        stillInTheRace: "Y",
      },
      164: {
        id: 65437,
        startno: 164,
        firstName: "Juri",
        lastName: "Hollmann",
        nationCode: "GER",
        nationName: "Germany",
        birthDate: "1999-08-30",
        teamId: 21822,
        teamName: "Movistar Team",
        stillInTheRace: "Y",
      },
      165: {
        id: 38169,
        startno: 165,
        firstName: "Johan",
        lastName: "Jacobs",
        nationCode: "SUI",
        nationName: "Switzerland",
        birthDate: "1997-03-01",
        teamId: 21822,
        teamName: "Movistar Team",
        stillInTheRace: "Y",
      },
      166: {
        id: 6409,
        startno: 166,
        firstName: "Lluis",
        lastName: "Mas",
        nationCode: "ESP",
        nationName: "Spain",
        birthDate: "1989-10-15",
        teamId: 21822,
        teamName: "Movistar Team",
        stillInTheRace: "Y",
      },
      167: {
        id: 25690,
        startno: 167,
        firstName: "Gonzalo",
        lastName: "Serrano",
        nationCode: "ESP",
        nationName: "Spain",
        birthDate: "1994-08-17",
        teamId: 21822,
        teamName: "Movistar Team",
        stillInTheRace: "Y",
      },
      171: {
        id: 2211,
        startno: 171,
        firstName: "Michael",
        lastName: "Matthews",
        nationCode: "AUS",
        nationName: "Australia",
        birthDate: "1990-09-26",
        teamId: 21821,
        teamName: "Team BikeExchange",
        stillInTheRace: "Y",
      },
      172: {
        id: 2327,
        startno: 172,
        firstName: "Luke",
        lastName: "Durbridge",
        nationCode: "AUS",
        nationName: "Australia",
        birthDate: "1991-04-09",
        teamId: 21821,
        teamName: "Team BikeExchange",
        stillInTheRace: "Y",
      },
      173: {
        id: 18079,
        startno: 173,
        firstName: "Alex",
        lastName: "Edmondson",
        nationCode: "AUS",
        nationName: "Australia",
        birthDate: "1993-12-22",
        teamId: 21821,
        teamName: "Team BikeExchange",
        stillInTheRace: "Y",
      },
      174: {
        id: 50030,
        startno: 174,
        firstName: "Barnabas",
        lastName: "Peak",
        nationCode: "HUN",
        nationName: "Hungary",
        birthDate: "1998-11-29",
        teamId: 21821,
        teamName: "Team BikeExchange",
        stillInTheRace: "Y",
      },
      175: {
        id: 39286,
        startno: 175,
        firstName: "Alexander",
        lastName: "Konychev",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1998-07-25",
        teamId: 21821,
        teamName: "Team BikeExchange",
        stillInTheRace: "Y",
      },
      176: {
        id: 2212,
        startno: 176,
        firstName: "Jack",
        lastName: "Bauer",
        nationCode: "NZL",
        nationName: "New Zealand",
        birthDate: "1985-04-07",
        teamId: 21821,
        teamName: "Team BikeExchange",
        stillInTheRace: "Y",
      },
      177: {
        id: 44537,
        startno: 177,
        firstName: "Robert",
        lastName: "Stannard",
        nationCode: "AUS",
        nationName: "Australia",
        birthDate: "1998-09-16",
        teamId: 21821,
        teamName: "Team BikeExchange",
        stillInTheRace: "Y",
      },
      181: {
        id: 3933,
        startno: 181,
        firstName: "Matteo",
        lastName: "Trentin",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1989-08-02",
        teamId: 21838,
        teamName: "UAE Team Emirates",
        stillInTheRace: "Y",
      },
      182: {
        id: 18575,
        startno: 182,
        firstName: "Fernando",
        lastName: "Gaviria",
        nationCode: "COL",
        nationName: "Colombia",
        birthDate: "1994-08-19",
        teamId: 21838,
        teamName: "UAE Team Emirates",
        stillInTheRace: "Y",
      },
      183: {
        id: 406,
        startno: 183,
        firstName: "Alexander",
        lastName: "Kristoff",
        nationCode: "NOR",
        nationName: "Norway",
        birthDate: "1987-07-05",
        teamId: 21838,
        teamName: "UAE Team Emirates",
        stillInTheRace: "Y",
      },
      184: {
        id: 16998,
        startno: 184,
        firstName: "Ryan",
        lastName: "Gibbons",
        nationCode: "RSA",
        nationName: "South Africa",
        birthDate: "1994-08-13",
        teamId: 21838,
        teamName: "UAE Team Emirates",
        stillInTheRace: "Y",
      },
      185: {
        id: 37777,
        startno: 185,
        firstName: "Ivo",
        lastName: "Oliveira",
        nationCode: "POR",
        nationName: "Portugal",
        birthDate: "1996-09-05",
        teamId: 21838,
        teamName: "UAE Team Emirates",
        stillInTheRace: "Y",
      },
      186: {
        id: 14325,
        startno: 186,
        firstName: "Rui",
        lastName: "Oliveira",
        nationCode: "POR",
        nationName: "Portugal",
        birthDate: "1996-09-05",
        teamId: 21838,
        teamName: "UAE Team Emirates",
        stillInTheRace: "Y",
      },
      187: {
        id: 66265,
        startno: 187,
        firstName: "Mikkel",
        lastName: "Bjerg",
        nationCode: "DEN",
        nationName: "Denmark",
        birthDate: "1998-11-03",
        teamId: 21838,
        teamName: "UAE Team Emirates",
        stillInTheRace: "Y",
      },
      191: {
        id: 16672,
        startno: 191,
        firstName: "Mathieu",
        lastName: "van der Poel",
        nationCode: "NED",
        nationName: "Netherlands",
        birthDate: "1995-01-19",
        teamId: 21844,
        teamName: "Alpecin-Fenix",
        stillInTheRace: "Y",
      },
      192: {
        id: 28169,
        startno: 192,
        firstName: "Dries",
        lastName: "De Bondt",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1991-07-04",
        teamId: 21844,
        teamName: "Alpecin-Fenix",
        stillInTheRace: "Y",
      },
      193: {
        id: 12482,
        startno: 193,
        firstName: "Xandro",
        lastName: "Meurisse",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1992-01-31",
        teamId: 21844,
        teamName: "Alpecin-Fenix",
        stillInTheRace: "Y",
      },
      194: {
        id: 22679,
        startno: 194,
        firstName: "Floris",
        lastName: "De Tier",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1992-01-20",
        teamId: 21844,
        teamName: "Alpecin-Fenix",
        stillInTheRace: "Y",
      },
      195: {
        id: 20945,
        startno: 195,
        firstName: "Otto",
        lastName: "Vergaerde",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1994-07-15",
        teamId: 21844,
        teamName: "Alpecin-Fenix",
        stillInTheRace: "Y",
      },
      196: {
        id: 6266,
        startno: 196,
        firstName: "Petr",
        lastName: "Vakoc",
        nationCode: "CZE",
        nationName: "Czech Republic",
        birthDate: "1992-07-11",
        teamId: 21844,
        teamName: "Alpecin-Fenix",
        stillInTheRace: "Y",
      },
      197: {
        id: 7964,
        startno: 197,
        firstName: "Gianni",
        lastName: "Vermeersch",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1992-11-19",
        teamId: 21844,
        teamName: "Alpecin-Fenix",
        stillInTheRace: "Y",
      },
      201: {
        id: 27334,
        startno: 201,
        firstName: "Remy",
        lastName: "Mertz",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1995-07-17",
        teamId: 21852,
        teamName: "Bingoal-WB",
        stillInTheRace: "Y",
      },
      202: {
        id: 37996,
        startno: 202,
        firstName: "Milan",
        lastName: "Menten",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1996-10-31",
        teamId: 21852,
        teamName: "Bingoal-WB",
        stillInTheRace: "Y",
      },
      203: {
        id: 20822,
        startno: 203,
        firstName: "Arjen",
        lastName: "Livyns",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1994-09-01",
        teamId: 21852,
        teamName: "Bingoal-WB",
        stillInTheRace: "Y",
      },
      204: {
        id: 71209,
        startno: 204,
        firstName: "Tom",
        lastName: "Paquot",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1999-09-22",
        teamId: 21852,
        teamName: "Bingoal-WB",
        stillInTheRace: "Y",
      },
      205: {
        id: 742,
        startno: 205,
        firstName: "Jelle",
        lastName: "Vanendert",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1985-02-19",
        teamId: 21852,
        teamName: "Bingoal-WB",
        stillInTheRace: "Y",
      },
      206: {
        id: 56031,
        startno: 206,
        firstName: "Joel",
        lastName: "Suter",
        nationCode: "SUI",
        nationName: "Switzerland",
        birthDate: "1998-10-25",
        teamId: 21852,
        teamName: "Bingoal-WB",
        stillInTheRace: "Y",
      },
      207: {
        id: 27086,
        startno: 207,
        firstName: "Tom",
        lastName: "Wirtgen",
        nationCode: "LUX",
        nationName: "Luxembourg",
        birthDate: "1996-03-04",
        teamId: 21852,
        teamName: "Bingoal-WB",
        stillInTheRace: "Y",
      },
      211: {
        id: 37466,
        startno: 211,
        firstName: "C\u00e9dric",
        lastName: "Beullens",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1997-01-27",
        teamId: 21863,
        teamName: "Sport Vlaanderen-Baloise",
        stillInTheRace: "Y",
      },
      212: {
        id: 54613,
        startno: 212,
        firstName: "Alex",
        lastName: "Colman",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1998-07-22",
        teamId: 21863,
        teamName: "Sport Vlaanderen-Baloise",
        stillInTheRace: "Y",
      },
      213: {
        id: 32870,
        startno: 213,
        firstName: "Lindsay",
        lastName: "De Vylder",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1995-05-30",
        teamId: 21863,
        teamName: "Sport Vlaanderen-Baloise",
        stillInTheRace: "Y",
      },
      214: {
        id: 66276,
        startno: 214,
        firstName: "Gilles",
        lastName: "De Wilde",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1999-10-12",
        teamId: 21863,
        teamName: "Sport Vlaanderen-Baloise",
        stillInTheRace: "Y",
      },
      215: {
        id: 10952,
        startno: 215,
        firstName: "Thomas",
        lastName: "Sprengers",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1990-02-05",
        teamId: 21863,
        teamName: "Sport Vlaanderen-Baloise",
        stillInTheRace: "Y",
      },
      216: {
        id: 45356,
        startno: 216,
        firstName: "Aaron",
        lastName: "Van Poucke",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1998-04-04",
        teamId: 21863,
        teamName: "Sport Vlaanderen-Baloise",
        stillInTheRace: "Y",
      },
      217: {
        id: 64654,
        startno: 217,
        firstName: "Ward",
        lastName: "Vanhoof",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1999-04-18",
        teamId: 21863,
        teamName: "Sport Vlaanderen-Baloise",
        stillInTheRace: "Y",
      },
      221: {
        id: 29,
        startno: 221,
        firstName: "Niki",
        lastName: "Terpstra",
        nationCode: "NED",
        nationName: "Netherlands",
        birthDate: "1984-05-18",
        teamId: 21867,
        teamName: "Total Direct Energie",
        stillInTheRace: "Y",
      },
      222: {
        id: 31,
        startno: 222,
        firstName: "Edvald",
        lastName: "Boasson Hagen",
        nationCode: "NOR",
        nationName: "Norway",
        birthDate: "1987-05-17",
        teamId: 21867,
        teamName: "Total Direct Energie",
        stillInTheRace: "Y",
      },
      223: {
        id: 37800,
        startno: 223,
        firstName: "Florian",
        lastName: "Ma\u00eetre",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "1996-09-03",
        teamId: 21867,
        teamName: "Total Direct Energie",
        stillInTheRace: "Y",
      },
      224: {
        id: 2326,
        startno: 224,
        firstName: "Geoffrey",
        lastName: "Soupe",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "1988-03-22",
        teamId: 21867,
        teamName: "Total Direct Energie",
        stillInTheRace: "Y",
      },
      225: {
        id: 330,
        startno: 225,
        firstName: "Damien",
        lastName: "Gaudin",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "1986-08-20",
        teamId: 21867,
        teamName: "Total Direct Energie",
        stillInTheRace: "Y",
      },
      226: {
        id: 16637,
        startno: 226,
        firstName: "Anthony",
        lastName: "Turgis",
        nationCode: "FRA",
        nationName: "France",
        birthDate: "1994-05-16",
        teamId: 21867,
        teamName: "Total Direct Energie",
        stillInTheRace: "Y",
      },
      227: {
        id: 16789,
        startno: 227,
        firstName: "Dries",
        lastName: "van Gestel",
        nationCode: "BEL",
        nationName: "Belgium",
        birthDate: "1994-09-30",
        teamId: 21867,
        teamName: "Total Direct Energie",
        stillInTheRace: "Y",
      },
      231: {
        id: 16807,
        startno: 231,
        firstName: "Markus",
        lastName: "Hoelgaard",
        nationCode: "NOR",
        nationName: "Norway",
        birthDate: "1994-10-04",
        teamId: 21797,
        teamName: "Uno-X Pro Cycling Team",
        stillInTheRace: "Y",
      },
      232: {
        id: 67824,
        startno: 232,
        firstName: "Jonas Iversby",
        lastName: "Hvideberg",
        nationCode: "NOR",
        nationName: "Norway",
        birthDate: "1999-02-09",
        teamId: 21797,
        teamName: "Uno-X Pro Cycling Team",
        stillInTheRace: "Y",
      },
      233: {
        id: 27230,
        startno: 233,
        firstName: "Kristoffer",
        lastName: "Halvorsen",
        nationCode: "NOR",
        nationName: "Norway",
        birthDate: "1996-04-13",
        teamId: 21797,
        teamName: "Uno-X Pro Cycling Team",
        stillInTheRace: "Y",
      },
      234: {
        id: 57958,
        startno: 234,
        firstName: "Erik",
        lastName: "Resell",
        nationCode: "NOR",
        nationName: "Norway",
        birthDate: "1996-09-28",
        teamId: 21797,
        teamName: "Uno-X Pro Cycling Team",
        stillInTheRace: "Y",
      },
      235: {
        id: 27232,
        startno: 235,
        firstName: "Anders",
        lastName: "Skaarseth",
        nationCode: "NOR",
        nationName: "Norway",
        birthDate: "1995-05-07",
        teamId: 21797,
        teamName: "Uno-X Pro Cycling Team",
        stillInTheRace: "Y",
      },
      236: {
        id: 37303,
        startno: 236,
        firstName: "Rasmus",
        lastName: "Tiller",
        nationCode: "NOR",
        nationName: "Norway",
        birthDate: "1996-07-28",
        teamId: 21797,
        teamName: "Uno-X Pro Cycling Team",
        stillInTheRace: "Y",
      },
      237: {
        id: 37304,
        startno: 237,
        firstName: "Syver",
        lastName: "W\u00e6rsted",
        nationCode: "NOR",
        nationName: "Norway",
        birthDate: "1996-08-08",
        teamId: 21797,
        teamName: "Uno-X Pro Cycling Team",
        stillInTheRace: "Y",
      },
      241: {
        id: 33313,
        startno: 241,
        firstName: "Andrea",
        lastName: "Di Renzo",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1995-01-24",
        teamId: 21869,
        teamName: "Vini Zab\u00fa",
        stillInTheRace: "Y",
      },
      242: {
        id: 34979,
        startno: 242,
        firstName: "Roberto",
        lastName: "Gonzalez",
        nationCode: "PAN",
        nationName: "Panama",
        birthDate: "1994-05-21",
        teamId: 21869,
        teamName: "Vini Zab\u00fa",
        stillInTheRace: "Y",
      },
      243: {
        id: 28780,
        startno: 243,
        firstName: "Leonardo",
        lastName: "Tortomasi",
        nationCode: "ITA",
        nationName: "Italy",
        birthDate: "1994-01-21",
        teamId: 21869,
        teamName: "Vini Zab\u00fa",
        stillInTheRace: "Y",
      },
      244: {
        id: 37504,
        startno: 244,
        firstName: "Jan",
        lastName: "Petelin",
        nationCode: "LUX",
        nationName: "Luxembourg",
        birthDate: "1996-07-02",
        teamId: 21869,
        teamName: "Vini Zab\u00fa",
        stillInTheRace: "Y",
      },
      245: {
        id: 52551,
        startno: 245,
        firstName: "Joab",
        lastName: "Schneiter",
        nationCode: "SUI",
        nationName: "Switzerland",
        birthDate: "1998-08-06",
        teamId: 21869,
        teamName: "Vini Zab\u00fa",
        stillInTheRace: "Y",
      },
      246: {
        id: 16892,
        startno: 246,
        firstName: "Wout",
        lastName: "Van Elzakker",
        nationCode: "NED",
        nationName: "Netherlands",
        birthDate: "1998-11-14",
        teamId: 21869,
        teamName: "Vini Zab\u00fa",
        stillInTheRace: "Y",
      },
      247: {
        id: 16989,
        startno: 247,
        firstName: "Etienne",
        lastName: "Van Empel",
        nationCode: "NED",
        nationName: "Netherlands",
        birthDate: "1994-04-14",
        teamId: 21869,
        teamName: "Vini Zab\u00fa",
        stillInTheRace: "Y",
      },
    },
  }
  const race = "E3 Classic"
  const raceID = 47

  return (
    <RacepageFS
      title={`${race} 2021`}
      startlistLocal={startlist}
      race={race}
      raceID={raceID}
    />
  )
}

export default Race2021
